.link {
    display: inline-block;
    width: fit-content;
    padding: .5rem 1rem;
    color: rgba(255,255,255,.5);
}

.link:hover {
    color: rgba(255,255,255,.8);
    text-decoration: none;
}

.icon {
    height: 30px;
    width: 30px;
}

.footerLinks {
    padding-bottom: 25px;
}