@import '../../../../index.scss';

.pagePath {
    width: 100%;
    min-height: 50px;
    background-color: $bgDark;
    color: #ddd;
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    z-index: 0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    
}

.bgRight, .bgLeft {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .05;
}

.bgLeft {
    background-position-x: right;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.bgRight {
    background-position-x: right;
}

@media only screen and (max-width: 520px) {
    .bgRight, .bgRight {
        background-size: cover;
    }
}