.container {
    padding-top: 25px;
    padding-bottom: 25px;
}

.row {
    background-color: #343a40;
    color: #ddd;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.colText {
    padding: 15px;
    text-align: center;
    margin-top:auto;
    margin-bottom: auto;
}

.colMap {
    padding:0;
}

.map {
    display: block;
}