@import '../../index.scss';

.header {
  position: fixed;
  top: 0;
  height: $headerHeight;
  width: 100%;
  background-color: $bgContainer;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  z-index: 999;
  // border-bottom: 1px solid red;    
}

.content {  
  margin-top: $headerHeight;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 998;
}

.bg {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url("../../assets/images/om.jpg");
  background-attachment: fixed;
}

.footer {
  // border-top: 1px solid red;
  background-color: $bgDark;
  text-align: center;
  z-index: 999;
  position: relative;
  margin-top: 25px;
}