$headerHeight: 100px;
$bgDark:#252525;

$bgContainer: rgba(240,240,240, 1);
$bgLight: rgba(255,255,255, 1);

$foreColor: rgba(30,30,30, 1);
$linkColor:  rgba(125,125,125, 1);
$linkColorHover: rgba(125,125,125, .8);

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

html {
    background-color: $bgDark;
    color: $foreColor;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body hr {
    border-top: 1px solid rgba(0,0,0,.5);;
}

.row {
    background-color: $bgContainer;
    margin-top: 25px;
    color: $foreColor;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.col {
    padding: 15px;
}

.ueberschrift {
    width: 100%;
    min-height: 50px;
    background-color: $bgDark;
    color: #ddd;
    font-size: 20px;
    padding-top: 5px;
    z-index: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    align-content: center;
    justify-content: center;
}

h1 {
    font-size: 1.5em;
}

h2 {
    font-size: 1.25em;
}

h3 {
    font-size: 1em;
}

a[href] {
    color: $linkColor;
}

a[href]:hover {
    color: $linkColorHover;
    text-decoration: underline;
}