@import '../../../index.scss';

.navBar {
    padding: 0;
    
}

.center .brand {
    color: rgba(255,255,255,.5);
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.navBar .brandMobile { 
    color: rgba(0255,255,255,.5);
    padding: 0;
    margin: 0;
    max-width: 75%;
}

.brandDiv {
    display: inline-block;
    @media screen and (max-width: 340px) {
        display: none !important;
    }
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.collapse {
    background-color: $bgContainer;
    border-radius: .25rem;    
}

.dropdown {
    background-color: $bgContainer;
}

.dropdownItem:hover {
    background-color: $bgContainer;
}

.imgLogo {
    max-width: 100%;
    max-height: $headerHeight - 1;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.navItem {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.navToggler {
    margin-right: 15px;
    background-color: $bgContainer;
    color: $foreColor;
    border: 2px solid $foreColor;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(30,30,30, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.line {
    display: none;
    border-left: 2px solid rgba(255,255,255,.5);
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    @media screen and (min-width: $md) {
        display: block;
    }
}