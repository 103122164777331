@import '../../index.scss';

.col1 {
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.colGamma {
    padding: 10px;    
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: $bgDark;
}

.pad15 {
    padding: 15px;
}

.img {
    max-width: 100%;
    max-height: 175px;
}