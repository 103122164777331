@import '../../index.scss';

.colImg {
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.pad15 {
    padding: 15px;
}

.img {
    max-width: 100%;
    max-height: 200px;
}

// .imgR {
//     max-height: 200px;
//     float: right;
// }

.imgBig {
    width: 100%;
}