@import '../../index.scss';

.col1 {
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.pad15 {
    padding: 15px;
}

.img {
    width: 100%;
}